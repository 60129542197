import React from 'react'

import Layout from 'components/Layout'

const Priser = ({ data }) => (
  <Layout pageTitle='Priser'>
    <oma-grid-row>
      <div className='section'>
        <h1 className='heading page__heading'>Priser</h1>
        <p className='section__text'>
          <span className='section__text section__text--strong'>
            Naprapatbehandling:
          </span>{' '}
          740:- (1/4 2024)
          <br />
          <span className='section__text section__text--strong'>
            Friskvårdsbehandlingar
          </span>{' '}
          925:- (1/11 2024)
          <br />
          <span className='section__text section__text--strong'>
            Gummiband
          </span>{' '}
          80:-
          <br />
          <span className='section__text section__text--strong'>
            Linnex linement
          </span>{' '}
          170:-
          <br />
        </p>
        <p className='section__text'>
          Vi säljer även Backontrack produkter. Fråga oss om pris.
        </p>
      </div>
    </oma-grid-row>
  </Layout>
)

export default Priser
